import {
	Container,
	FormControl,
	TextField,
	Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DatePicker  } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider  } from '@mui/x-date-pickers';
import { AdapterDayjs  } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc.js';

import FormFieldView, { Values } from '../components/FormFieldView';
import ViewModel, { ModelNotFoundError, InvalidModelInputError } from '../viewmodels/ViewModel';
import PFFTokenViewModel from '../viewmodels/PFFTokenViewModel'
import Form from '../models/Form';
import PFFormEntry from '../models/PFFormEntry';

type Props = {
	pffTokenVM: PFFTokenViewModel;
	pfFormEntryVM: ViewModel<PFFormEntry>;
}

const PFFView: React.FC<Props> = ({ pffTokenVM, pfFormEntryVM }) => {
	dayjs.extend(utc);

	const navigate = useNavigate();
	const { token } = useParams();
	const [form, setForm] = useState<Form>();
	const [date] = useState<Dayjs>(dayjs());
	const [tokenExpired, setTokenExpired] = useState(false);
	const [errors, setErrors] = useState({});

	async function save(values: Values) {
		const form_field_entries = Object.keys(values).map((id: string) => {
			return {
				form_field: Number(id),
				value: values[Number(id)]
			}
		});

		const data: PFFormEntry = { token: token ?? '', date: date.utc().format('YYYY-MM-DD'), form_field_entries };
		try {
			await pfFormEntryVM.performAction('create', { data });
			navigate('/pff/success');
		}
		catch(err) {
			if(err instanceof InvalidModelInputError) {
				setErrors(err.errors);
			}
			else {
				throw err;
			}
		}
	}

	useEffect(() => {
		async function fetchData() {
			try {
				const data = await pffTokenVM.performAction('retrieve', { token });
				setForm(data.form);
			}	
			catch(err) { 
				if(err instanceof ModelNotFoundError) {
					setTokenExpired(true);
				}
			}
		}

		fetchData();
	}, [token, pffTokenVM]);
	
	return (
		<div>
			{!tokenExpired && <Container>
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<TextField
						label="Form"
						value={ form?.name ?? -1 }	
						disabled={true}
					/>
				</FormControl>		
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker label='Date' value={date} disabled={true}/>
					</LocalizationProvider>
				</FormControl>
				{form && <FormFieldView form={form} save={save} hideNotes={true} errors={errors}/>}		
			</Container>}
			{tokenExpired && <Typography>Token is expired! Please request a new one.</Typography>}
		</div>
	);
}

export default PFFView;
