import {
	Box,
	Container,
	Typography,
	Stack,
	IconButton,
	Tooltip,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import NavBar from '../components/NavBar';
import { UnauthError } from '../viewmodels/ViewModel';
import UserViewModel from '../viewmodels/UserViewModel';
import User, { Organization } from '../models/User';

type Props = {
	userVM: UserViewModel;
}

const OrganizationView: React.FC<Props> = ({ userVM }) => {
	const navigate = useNavigate();
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [organization, setOrganization] = useState<Organization>();
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		async function fetchData() {
			try {
				const user: User = await userVM.performAction('retrieve');
				setOrganization(user.organization)
				setIsAdmin(user.is_admin);
				if(user.is_admin) {
					setUsers(await userVM.performAction('get'));
				}
			}	
			catch(err) {
				if(err instanceof UnauthError) {
					navigate('/login')
				}
				else {
					alert('An unexpected error occurred, please contact admin@polfwack.tech.');
				}
			}
		}

		fetchData();
	}, [navigate, userVM]);

	const headers = ['Username', 'Email', 'First Name', 'Last Name'];

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>
			<Box component='main' sx={{ flexGrow: 1 }}>
				<Typography variant='h1' sx={{ textAlign: 'center' }}>Organization</Typography>
				<Container>
					<Stack spacing="2rem">
						<Box sx={{ border: 5, borderColor: 'primary.dark', borderRadius: '2rem', p: '1rem', }}>
							<Typography>Organization Name: { organization?.name }</Typography>
						</Box>
						{isAdmin && <Paper>				
							<Stack spacing="1rem">
								<Stack direction="row" spacing="1rem" sx={{ p: '1rem' }}>
									<Typography variant="h4">Users</Typography>
									<Tooltip title="Create New User">
										<IconButton 
											sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { bgcolor: 'secondary.dark' } }} 
											onClick={() => { navigate('/users/new') }}
										>
											<AddIcon/>
										</IconButton>
									</Tooltip>
								</Stack>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
											{headers.map(header => (
											<TableCell key={header}>{header}</TableCell>
											))}	
											</TableRow>
										</TableHead>
										<TableBody>
										{users.map(user => (
										<TableRow key={user.username}>
											<TableCell>{user.username}</TableCell>	
											<TableCell>{user.email}</TableCell>	
											<TableCell>{user.first_name}</TableCell>	
											<TableCell>{user.last_name}</TableCell>	
										</TableRow>
										))}
										</TableBody>
									</Table>
								</TableContainer>
							</Stack>
						</Paper>}
					</Stack>
				</Container>
			</Box>
		</Box>
	);
}

export default OrganizationView;
