import axios from 'axios';

const SERVER_URL = window.location.port === '' ?  `${window.location.origin}/api` : `http://${window.location.hostname}:8000/api`;

type RequestConfig = {
	method: string;
	path: string;
	data?: any;
}

async function unauthRequest(config: RequestConfig, client: Function = axios): Promise<any> {
	const { path, method, data } = config;
	const requestConfig = {
		method,
		data,
		url: `${SERVER_URL}/${path}/`
	}

	return (await client(requestConfig)).data;
}

async function refreshToken(client: Function = axios): Promise<void> {
	const data = {
		refresh: localStorage.getItem('refresh')
	}

	try {
		const responseData = await unauthRequest({ method: 'post', path: 'token/refresh', data }, client);
		localStorage.setItem('access', responseData.access);
	}
	catch(err) {
		if(err instanceof axios.AxiosError) {
			if(err.response?.status === 401) {
				localStorage.setItem('refresh', 'null');
			}
		}

		throw err;
	}
}

async function authRequest(config: RequestConfig, client: Function = axios): Promise<any> {
	const { path, method, data } = config;
	const requestConfig = {
		url: `${SERVER_URL}/${path}/`,
		method,
		data,
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('access')}`
		}
	}

	try {
		return (await client(requestConfig)).data;
	}
	catch(err) {
		if(axios.isAxiosError(err)) {
			if(err.response?.status === 401 && localStorage.getItem('refresh') !== 'null') {
				await refreshToken(client);
				return await authRequest(config, client);
			}
		}
		
		throw err;
	}
}

export {
	unauthRequest,
	authRequest,
	refreshToken,
	RequestConfig,
}
