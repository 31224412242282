import {
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material';

import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
	action?: Function;
	link: string;
}

const NavLink: React.FC<PropsWithChildren<Props>> = ({ children, action, link }) => {
	const navigate = useNavigate();

	return (
		<ListItem disablePadding>
			<ListItemButton sx={{ '&:hover': { bgcolor: 'primary.dark' } }} onClick={() => { if(action) { action() } navigate(link) }}>
				<ListItemText primary={children} primaryTypographyProps={{ color: 'primary.contrastText', variant: 'h2', fontSize: '2.2rem' }}/>
			</ListItemButton>
		</ListItem>
	);
}

export default NavLink;
