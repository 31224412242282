import ViewModel, { ActionConfig, InvalidModelInputError, ModelNotFoundError } from './ViewModel';
import User from '../models/User';
import axios from 'axios';

class UserViewModel extends ViewModel<User> {
	[key: string]: any;

	protected actions: string[] = ['retrieve', 'get', 'create'];
	protected unauthActions: string[] = ['password_reset'];
	private user?: User;

	constructor(client: Function = axios) {
		super('organization/users', [], client);
	}

	async retrieve(config: ActionConfig, requestFunction: Function): Promise<User> {
		const requestBody = {
			method: 'get',
			path: 'users'
		}
		
		this.user = await requestFunction(requestBody, this.client);
		return (this.user as User);
	}
	
	async password_reset(config: ActionConfig, requestFunction: Function): Promise<void> {
		const requestBody = {
			method: 'post',
			data: config.data,
			path: `users/password_reset`
		}

		try {
			await requestFunction(requestBody, this.client);
		}
		catch(err) {
			if(axios.isAxiosError(err)) {
				if(err.response?.status === 422) {
					throw new InvalidModelInputError('Invalid input for password reset', err.response?.data?.errors)
				}
				else if(err.response?.status === 404) {
					throw new ModelNotFoundError(err.response?.data?.detail, -1);
				}
			}

			throw err;
		}
	}
}

export default UserViewModel;
