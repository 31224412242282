import {
	Box,
	Typography,
	Container,
	IconButton,
	Tooltip,
	TextField,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
//import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ViewModel, { UnauthError, UnexpectedError } from '../viewmodels/ViewModel';
import Patient from '../models/Patient';
import NavBar from '../components/NavBar';

type Props = {
	title: string;
	patientVM: ViewModel<Patient>;
}

const Patients: React.FC<Props> = ({ title, patientVM }: Props) => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [filter, setFilter] = useState<string>('');
	const [filteredPatients, setFilteredPatients] = useState<Patient[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchPatients(): Promise<void> {
			try {
				const models: Patient[] = await patientVM.performAction('get');
				setPatients(models);
				setFilteredPatients(models);
			}
			catch(err) {
				if(err instanceof UnauthError) {
					navigate('/login');
				}
				else if(err instanceof UnexpectedError) {
					alert(err);
				}
			}
		}
		fetchPatients();	
	}, [navigate, patientVM]);

	useEffect(() => {
		setFilteredPatients(patients.filter(patient => {
			if (patient.first_name.toUpperCase().includes(filter.toUpperCase())) {
				return true;
			}
			if (patient.last_name.toUpperCase().includes(filter.toUpperCase())) {
				return true;
			}
			if (patient.email.toUpperCase().includes(filter.toUpperCase())) {
				return true;
			}

			return false;
		}));	
	}, [filter, patients]);

	const headers = ['First Name', 'Last Name', 'Email', 'Phone Number'];
	const keys = ['first_name', 'last_name', 'email', 'phone_number'];

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>
			<Box component='main' sx={{ flexGrow: 1 }}>
				<Typography variant='h1' sx={{ textAlign: 'center' }}>{ title ?? 'Patients' }</Typography>
				<Container>	
					<Box sx={{ mb: '.5rem', display: 'flex', alignItems: 'center' }}>
						<Tooltip title='Create New Patient'>
							<IconButton 
								aria-label='add a patient' 
								sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { bgcolor: 'secondary.dark' }, mx: '1rem' }} 
								onClick={() => { navigate('/patients/new') }}
							>
								<AddIcon/>
							</IconButton>
						</Tooltip>
						<TextField id='filter' label='Search' size='small' onChange={event => { setFilter(event.target.value); }}/>
					</Box>
					<Paper>
						<TableContainer>
							<Table aria-label='simple table'>
								<TableHead>
									<TableRow>
										{headers.map(header => (
										<TableCell key={header}>{header}</TableCell>
										))}
										<TableCell>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredPatients.map(ele => (
									<TableRow key={ele.id}>
										{keys.map(key => (
										<TableCell key={ele.id + '-' + key}>{ele[key]}</TableCell>
										))}
										<TableCell>
										{/* Not ready for this yet
											<Tooltip title='Add New Form Entry'>
												<IconButton
													size='small'
													sx={{ color: 'secondary.main' }}
													onClick={() => { navigate(`/form_entries/new/${ele.id}`) }}
												>
													<NoteAddIcon fontSize='small'/>
												</IconButton>
											</Tooltip>
											*/}
											<Tooltip title='View Patient Info'>
												<IconButton
													size='small'
													onClick={() => { navigate(`/patients/view/${ele.id}`) }}
												>
													<VisibilityIcon fontSize='small'/>
												</IconButton>
											</Tooltip>
											<Tooltip title='Edit Patient Info'>
												<IconButton
													size='small'
													onClick={() => { navigate(`/patients/edit/${ele.id}`) }}
												>
													<EditIcon fontSize='small'/>
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
									))}	
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Container>
			</Box>
		</Box>
	);
}

export default Patients;
