import {
	FormControl,
	TextField,
	Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import FormEntry, { FormFieldEntry } from '../models/FormEntry';
import Form, { FormField } from '../models/Form';

type Props = {
	save: Function;
	formEntry?: FormEntry;	
	readOnly?: boolean;	
	form?: Form;
	hideNotes?: boolean;
	errors: any;
}

type Values = {
	[key: number]: string;
}

const FormFieldView: React.FC<Props> = ({ formEntry, save, readOnly, form, hideNotes, errors }) => {
	const [values, setValues] = useState<Values>({})
	const [notes, setNotes] = useState('');


	function handleValueChange(value: string, id: number) {
		setValues({
			...values,
			[id]: value
		});	
	}

	useEffect(() => {
		if(formEntry && formEntry.form_field_entries) {
			const formEntryValues: Values = {}
			form?.fields.forEach((field: FormField) => {
				var value = '';
				const formFieldEntry = formEntry.form_field_entries.find((entry: FormFieldEntry) => entry.form_field === field.id);
				if (formFieldEntry) {
					value = formFieldEntry.value;
				}
				formEntryValues[(field.id as number)] = value;
			});

			setValues(formEntryValues);
			setNotes(formEntry.notes);
		}
	}, [formEntry, form]);

	
	return (
		<div>
		{form?.fields.map((field: FormField, i: number) => (
			<FormControl key={`field-${field.id}`} fullWidth sx={{ mt: '1rem' }}>
				<TextField 
					label={field.label} 
					value={values[field.id as number] ? values[field.id as number] : '' } 
					error={errors.form_field_entries ? Object.keys(errors.form_field_entries[i]).length > 0 : false}
					helperText={errors.form_field_entries && Object.keys(errors.form_field_entries[i]).length > 0 ? errors.form_field_entries[i].value : ''}
					onChange={event => { if(!readOnly) {  handleValueChange(event.target.value, field.id as number) } }}
				/>
			</FormControl>
			))
		}	

		{!hideNotes && <FormControl fullWidth sx={{ mt: '1rem' }}>
			<TextField 
				label='Notes' 
				value={notes} 
				error={errors.notes ? true : false}
				helperText={errors.notes ? errors.notes[0] : ''}
				rows={4} 
				onChange={ event => { if(!readOnly) { setNotes(event.target.value ); } } } 
				multiline
			/>						
		</FormControl>}
		{ !readOnly && <Button variant='contained' color='secondary' sx={{ mt: '1rem', mr: '.5rem' }}onClick={() => { save(values, notes) }}>Save</Button> }
		</div>
	);
}

export default FormFieldView;

export { Values };
