import { Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type Props = any;

const InfoBox: React.FC<PropsWithChildren<Props>> = ({ children }) => {
		
	return (
		<Stack sx={{ minWidth: '20rem', minHeight: '20rem', bgcolor: 'white', borderRadius: '2.5rem', p: '1.5rem', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} spacing='1rem'>
			{ children }
		</Stack>
	);
}

export default InfoBox;
