import {
	Box,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Button,
	FormHelperText
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Component } from 'react';
import { DatePicker  } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider  } from '@mui/x-date-pickers';
import { AdapterDayjs  } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';

import ViewModel, { UnauthError, InvalidModelInputError, UnexpectedError } from '../viewmodels/ViewModel';
import FormFieldView from './FormFieldView';
import FormEntry, { FormFieldEntry } from '../models/FormEntry';
import Form from '../models/Form';
import Patient from '../models/Patient';


type Props = {
	formEntryVM: ViewModel<FormEntry>;
	formEntry?: FormEntry;	
	forms: Form[];
	patients: Patient[];
	readOnly?: boolean;
}

type State = {
	id: number | string;
	patient: number | string;
	form: number | string;
	date: string;
	goBack: boolean;
	auth: boolean;
	errors: any;
}

class FormEntryView extends Component<Props, State> {
	state: State = {
		id: this.props.formEntry && this.props.formEntry.id ? this.props.formEntry.id : '',
		patient: this.props.formEntry && this.props.formEntry.patient ? this.props.formEntry.patient : '',
		form: this.props.formEntry && typeof this.props.formEntry.form === 'number' ? this.props.formEntry.form as number : '',
		date: this.props.formEntry && this.props.formEntry.date ? this.props.formEntry.date: '',
		goBack: false,
		auth: false,
		errors: {}
	}

	constructor(props: Props) {
		super(props);	
		this.save = this.save.bind(this);
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if(!prevProps.formEntry && this.props.formEntry) {
			const { patient, form, date, id } = this.props.formEntry;
			var formId: number | string = '';

			if(typeof form === 'number') {
				formId = form as number;
			}

			this.setState({
				id: id ? id : '',
				patient,
				form: formId,
				date: date ? date : '',
			});
		}	
	}

	async save(formFieldValues: FormFieldEntry[], notes: string) {
		try {
			const { patient, form, date }	= this.state;
			const form_field_entries = Object.keys(formFieldValues).map((id: string) => {
				return {
					form_field: Number(id),
					value: formFieldValues[Number(id)]
				}
			});
			console.log(form_field_entries)
			if(this.state.id === '') {
				await this.props.formEntryVM.performAction('create', { data: { patient, form, date, form_field_entries, notes } });
			}
			else {
				await this.props.formEntryVM.performAction('update', { data: { patient, form, date, form_field_entries, notes }, id: Number(this.state.id) });
			}
			this.setState({ goBack: true });
		}
		catch(err) {
			if(err instanceof UnauthError) {
				this.setState({ auth: true });
			}
			else if(err instanceof InvalidModelInputError) {
				this.setState({ errors: err.errors })
			}
			else if(err instanceof UnexpectedError) {
				alert('An unexpected error occurred, please contact admin@polfwack.tech.');
			}
			else {
				throw err;
			}
		}
	}

	render() {
		dayjs.extend(utc);
		return (
			<Box>
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<InputLabel id='patient-select-label' error={this.state.errors.patient ? true : false}>Patient</InputLabel>
					<Select 
						labelId='patient-select-label' 
						label='Patient' 
						value={this.state.patient} 
						error={this.state.errors.patient ? true : false}
						onChange={event => { if(!this.props.readOnly) { this.setState({ patient: Number(event.target.value) }) }}}
					>
						{(this.props.patients ? this.props.patients : []).map(patient => (
						<MenuItem key={`patient-${patient.id}`} value={patient.id}>{patient.first_name} {patient.last_name}</MenuItem>
						))}
					</Select>
					{this.state.errors.patient && <FormHelperText error={true}>{ this.state.errors.patient[0] }</FormHelperText>}	
				</FormControl>
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<InputLabel id='form-select-label' error={this.state.errors.form ? true : false}>Form</InputLabel>
					<Select 
						labelId='form-select-label' 
						value={this.state.form} 
						label='Form' 
						error={this.state.errors.form ? true : false}
						onChange={event => { if(!this.props.readOnly) { this.setState({ form: Number(event.target.value) }) } }}
					>
						{(this.props.forms ? this.props.forms : []).filter(form => this.props.readOnly || form.active).map(form => (
						<MenuItem key={`form-${form.id}`} value={form.id}>{form.name}</MenuItem>
						))}
					</Select>
					{this.state.errors.form && <FormHelperText error={true}>{ this.state.errors.form[0] }</FormHelperText>}	
				</FormControl>
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker 
							label='Date' 
							value={dayjs.utc(this.state.date).local()} 
							onChange={newValue => { if(!this.props.readOnly) { this.setState({ date: (newValue ?? dayjs()).format('YYYY-MM-DD') }) } }}
						/>
					</LocalizationProvider>
				</FormControl>
				{(this.props.formEntry && this.state.form) ? <FormFieldView form={this.props.forms.find(form => form.id === this.state.form)} formEntry={this.props.formEntry} readOnly={this.props.readOnly} save={this.save} errors={this.state.errors}/> : <div></div>}
					
				<Button variant='contained' color='error' sx={{ mt: '1rem' }} onClick={() => { this.setState({ goBack: true }) }}>Cancel</Button>
				{ this.state.goBack && <Navigate to={this.state.patient !== -1 ? `/patients/view/${this.state.patient}` : '/'} replace={true}/> }
				{ this.state.auth && <Navigate to='/login' replace={true}/> }
			</Box>
		);
	}
}

export default FormEntryView;
