import {
	Box,
	Drawer,
	Divider,
	List
} from '@mui/material';
import React from 'react';

import NavLink from './NavLink';

const pages = [
	{
		name: 'Patients',
		link: '/patients'
	},
	{
		name: 'Organization',
		link: '/organization'
	},
	{
		name: 'Forms',
		link: '/forms'
	}
];

const NavBar: React.FC = () => {
	return (
		<Drawer variant='permanent' anchor='left' sx={{ width: '15vw', '& .MuiDrawer-paper': { width: '15vw' } }}>
			<Box sx={{ bgcolor: 'primary.main', height: '100%' }}>
				<List>
					{pages.map(page => (
						<NavLink key={page.name} link={page.link}>{page.name}</NavLink>
					))}
				</List>

				<Divider/>

				<List sx={{ position: 'absolute', bottom: '1rem', width: '100%' }}>
					<NavLink key='logout' link='/login' action={() => { localStorage.setItem('access', 'null'); localStorage.setItem('refresh', 'null'); }}>Logout</NavLink>
				</List>
			</Box>
		</Drawer>
	);
}

export default NavBar;
