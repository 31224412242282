import {
	Container,
	Typography,
	FormControl,
	TextField,
	Button,
	Box
} from '@mui/material'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ViewModel, { InvalidModelInputError } from '../viewmodels/ViewModel';
import PasswordResetToken from '../models/PasswordResetToken';

type Props = {
	passwordResetTokenVM: ViewModel<PasswordResetToken>;
}

const RequestPasswordResetView: React.FC<Props> = ({ passwordResetTokenVM }) => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState(false);
	const navigate = useNavigate();

	async function submit() {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if(!regex.test(email)) {
			setError(true);
			return;
		}

		try {
			await passwordResetTokenVM.performAction('create', { data: { email } });
			alert('Password reset sent.');
			navigate('/login');
		} 
		catch(err) {
			if(err instanceof InvalidModelInputError) {
				setError(true);
			}
			else {
				alert('An unexpected error occurred, contact admin@polfwack.tech.');
			}
		}
	}

	return (
		<Container>
			<Typography variant='h1' sx={{ textAlign: 'center', fontSize: '5rem' }}>Request Password Reset</Typography>
			<FormControl fullWidth sx={{
				mb: '1rem',
					'& .MuiTextField-root': {
						mt: '1rem'
					}
			}}>
				<TextField 
					id="email" 
					label="Email" 
					variant="standard" 
					value={email} 
					error={error}
					helperText={ error ? 'Enter a valid email address' : '' }
					onChange={event => { setEmail(event.target.value); }}
				/>
			</FormControl>
			<Box>
			<Button variant='contained' color='secondary' onClick={submit}>Submit</Button>
			<Button sx={{ mx: '1rem' }} variant='contained' color='error' onClick={() => { navigate('/login') }}>Cancel</Button>
			</Box>
		</Container>
	);
}

export default RequestPasswordResetView;
