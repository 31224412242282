import {
	Box,
	Typography,
	Container,
	Button,
	TextField,
	Stack,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Tooltip,
	IconButton
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc.js';

import NavBar from '../components/NavBar';
import ViewModel, { UnauthError, ModelNotFoundError, UnexpectedError } from '../viewmodels/ViewModel';
import Patient from '../models/Patient';
import FormEntry from '../models/FormEntry';
import Form from '../models/Form';

type Props = {
	patientVM: ViewModel<Patient>;
	formEntryVM: ViewModel<FormEntry>;
	formVM: ViewModel<Form>;
}

type ViewFormEntry = Omit<Omit<FormEntry, 'date'>, 'form'> & {
	date: Dayjs;
	form: Form;
}


const PatientView: React.FC<Props> = ({ patientVM, formEntryVM, formVM }) => {
	dayjs.extend(utc);
	const navigate = useNavigate();
	const [patient, setPatient] = useState<Patient>();
	const [formEntries, setFormEntries] = useState<ViewFormEntry[]>([]);
	const [filter, setFilter] = useState<string>('');
	const [filteredFormEntries, setFilteredFormEntries] = useState<ViewFormEntry[]>([]);
	const { id } = useParams();
	
	useEffect(() => {
		async function fetchData() {
			try {
				setPatient(await patientVM.performAction('retrieve', { id: Number(id) }));
				const formEntryData = (await formEntryVM.performAction('get')).filter((entry: FormEntry) => Number(entry.patient) === Number(id));
				const forms = await formVM.performAction('get');

				setFormEntries(formEntryData.map((entry: FormEntry): ViewFormEntry => {
					return {
						...entry,
						date: dayjs.utc(entry.date).local(),
						form: forms.find((ele: Form) => Number(ele.id) === Number(entry.form))
					}
				}));
			}
			catch(err) {
				if(err instanceof UnauthError) {
					navigate('/login');
				}

				alert(err);
			}
			
		}

		fetchData();
	}, [id, navigate, patientVM, formEntryVM, formVM]);

	useEffect(() => {
		setFilteredFormEntries(formEntries.filter<ViewFormEntry>((formEntry: ViewFormEntry): formEntry is ViewFormEntry => {
			if (formEntry.form.name.toUpperCase().includes(filter.toUpperCase())) {
				return true;
			}

			return false;
		}));
	}, [filter, formEntries]);

	async function deleteFormEntry(id: number) {
		try {
			await formEntryVM.performAction('delete', { id });
			setFormEntries(formEntries.filter(ele => ele.id !== id));
		}
		catch(err) {
			if(err instanceof UnauthError) {
				navigate('/login');
			}
			else if(err instanceof ModelNotFoundError) {
				alert('This form entry does not exist, please refresh the page and try again. If this continues to happen, please contact admin@polfwack.tech.')
			}
			else if(err instanceof UnexpectedError) {
				alert('An unexpected error occurred, please contact admin@polfwack.tech.')
				console.error(err);
			}
		}
	}

	const headers = ['Form Name', 'Date', 'Actions']

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>				
			<Box component='main' sx={{ flexGrow: 1, pb: '1rem' }}>
				<Typography variant='h1' sx={{ textAlign: 'center' }}>{ patient?.first_name } {patient?.last_name}</Typography>	
				<Container>
					<Box sx={{ my: '2rem' }}>
						<Tooltip title='Edit Patient Info'>
							<IconButton 
								aria-label='edit patient info' 
								sx={{ mb: '.5rem' }} 
								onClick={() => { navigate(`/patients/edit/${id}`) }}
							>
								<EditIcon/>
							</IconButton>
						</Tooltip>
						<Box sx={{ border: 5, borderColor: 'primary.dark', borderRadius: '2rem', p: '1rem', }}>
							<Typography>First Name: { patient?.first_name }</Typography>
							<Typography>Last Name: { patient?.last_name }</Typography>
							<Typography>Email: { patient?.email }</Typography>
							<Typography>Phone Number: { patient?.phone_number }</Typography>
						</Box>
					</Box>
					<Box sx={{ mb: '1rem' }}>
						<Typography variant='h2' sx={{ textAlign: 'center' }}>Charts</Typography>
						<Box sx={{ mb: '.5rem', display: 'flex', alignItems: 'center' }}>
							<Tooltip title='Create New Form Entry'>
								<IconButton 
									aria-label='add a form entry' 
									sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { bgcolor: 'secondary.dark' }, mx: '1rem' }} 
									onClick={() => { navigate(`/form_entry/new/${id}`) }}
								>
									<AddIcon/>
								</IconButton>
							</Tooltip>		
							<Tooltip title='Send Patient Form'>
								<IconButton 
									aria-label='send patient form' 
									sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { bgcolor: 'secondary.dark' } }} 
									onClick={() => { navigate(`/form_entry/send/${id}`); }}
								>
									<MailOutlineIcon/>
								</IconButton>
							</Tooltip>
							<TextField id='filter' label='Search' sx={{ mx: '1rem' }} onChange={event => { setFilter(event.target.value); }}/>		
						</Box>
						<TableContainer component={Paper}>
							<Table aria-label='simple table'>
								<TableHead>
									<TableRow>
										{headers.map(header => (
										<TableCell key={header}>{header}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredFormEntries.map(ele => (
									<TableRow key={ele.id}>
										<TableCell>{ele.form.name}</TableCell>
										<TableCell>{ele.date ? ele.date.format('YYYY-MM-DD') : ''}</TableCell>
										<TableCell>
											<Stack direction="row" spacing="0.2rem">
												<Tooltip title='View Form Entry'>
													<IconButton
														size='small'
														onClick={() => { navigate(`/form_entry/view/${ele.id}`) }}
													>
														<VisibilityIcon fontSize='small'/>
													</IconButton>
												</Tooltip>
												<Tooltip title="Edit Form Entry">
													<IconButton
														size='small'
														onClick={() => { navigate(`/form_entry/edit/${ele.id}`) }}
													>
														<EditIcon fontSize='small'/>
													</IconButton>
												</Tooltip>
												<Tooltip title="Delete Form Entry">
													<IconButton
														size='small'
														sx={{ bgcolor: 'error.main' , color: 'error.contrastText', '&:hover': { bgcolor: 'error.dark' }}}
														onClick={() => { deleteFormEntry(ele.id ?? -1) }}
													>
														<DeleteIcon fontSize='small'/>
													</IconButton>
												</Tooltip>
											</Stack>
										</TableCell>	
									</TableRow>
									))}	
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Button variant='contained' color='error' onClick={() => { navigate('/patients') }}>Cancel</Button>
				</Container>
			</Box>
		</Box>
	);
}

export default PatientView;
