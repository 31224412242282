import {  
	Box,
	Container,
	Typography,
	FormControl,
	TextField,
	Button
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import NavBar from '../components/NavBar';
import Patient from '../models/Patient';
import ViewModel, { UnauthError, InvalidModelInputError, UnexpectedError } from '../viewmodels/ViewModel';

type Props = {
	patientVM: ViewModel<Patient>;
	title: string;
}

const PatientView: React.FC<Props> = ({ patientVM, title }) => {
	const navigate = useNavigate();

	const { id } = useParams();
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [errors, setErrors] = useState<any>({});

	useEffect(() => {
		async function fetchData() {
			try {
				if(id) {
					const patient: Patient = await patientVM.performAction('retrieve', { id: Number(id) });

					setFirstName(patient.first_name);
					setLastName(patient.last_name);
					setEmail(patient.email);
					setPhoneNumber(patient.phone_number);
				}
			}
			catch(err) {
				if(err instanceof UnauthError) {
					navigate('/login');
				}

				throw err;
			}
		}
					
		fetchData();
	}, [id, navigate, patientVM]);

	async function savePatient() {
		const path: string = window.location.pathname;
		try {
			const body: Patient = {
				first_name: firstName,
				last_name: lastName,
				email,
				phone_number: phoneNumber,
			}
			var newPatient: Patient = body;
			if(path.includes('edit')) {
				newPatient = await patientVM.performAction('update', { id: Number(id), data: body });	
			}	
			else if(path.includes('new')) {
				newPatient = await patientVM.performAction('create', { data: body });
			}
			navigate(`/patients/view/${id ? id : newPatient.id }`);
		}
		catch(err) {
			if(err instanceof UnauthError) {
				navigate('/login');
			}
			else if(err instanceof InvalidModelInputError) {
				setErrors(err.errors);
			}
			else if(err instanceof UnexpectedError) {
				alert('An unexpected error occurred, please contact admin@polfwack.tech.')
			}
			else {
				throw err;
			}
		}
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>
			<Container>
				<Typography variant='h1' sx={{ textAlign: 'center' }}>{title}</Typography>
				<FormControl fullWidth sx={{ my: '1rem',
					'& .MuiTextField-root': {
						mt: '1rem'
					}
				}}>
					<TextField 
						id='first_name' 
						label='First Name' 
						variant='standard' 
						helperText={errors.first_name ? errors.first_name[0] : ''}
						error={errors.first_name ? true : false}
						value={firstName} 
						onChange={event => { setFirstName(event.target.value) }}
					/>	
					<TextField 
						id='last_name' 
						label='Last Name' 
						variant='standard' 
						helperText={errors.last_name ? errors.last_name[0] : ''}
						error={errors.last_name ? true : false}
						value={lastName} 
						onChange={event => { setLastName(event.target.value) }}
					/>	
					<TextField 
						id='email' 
						label='Email' 
						variant='standard' 
						helperText={errors.email ? errors.email[0] : ''}
						error={errors.email ? true : false}
						value={email} 
						onChange={event => { setEmail(event.target.value) }}
					/>	
					<TextField 
						id='phone_number' 
						label='Phone Number' 
						variant='standard' 
						helperText={errors.phone_number ? errors.phone_number[0] : ''}
						error={errors.phone_number ? true : false}
						value={phoneNumber} 
						onChange={event => { setPhoneNumber(event.target.value) }}
					/>	
				</FormControl>
				<Box sx={{ '& .MuiButton-root': { mr: '.5rem' } }}>
				<Button variant='contained' color='secondary' onClick={() => { savePatient() }}>Save</Button>
				<Button variant='contained' color='error' onClick={() => { navigate(id ? `/patients/view/${id}` : '/patients') }}>Cancel</Button>
				</Box>
			</Container>
		</Box>
	);
}

export default PatientView;
