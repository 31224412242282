import {
	Stack,
	Box,
	Typography,
	Button,
	List,
	ListItem,
	ListItemText,
	Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import InfoBox from '../components/InfoBox';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import ViewModel from '../viewmodels/ViewModel';
import ContactFormEntry from '../models/ContactFormEntry';

type Props = {
	contactFormEntryVM: ViewModel<ContactFormEntry>;
}

const LandingView: React.FC<Props> = ({ contactFormEntryVM }) => {
	const navigate = useNavigate();
	const isTabletOrLarger = useMediaQuery({ query: '(min-width: 768px)' });
	const isLargerThanPhone = useMediaQuery({ query: '(min-width: 480px)' })

	return (
		<Stack>
			<Stack sx={{ backgroundImage: `url(${require('../assets/cover.png')})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', minHeight: '100vh', minWidth: '100vw', justifyContent: 'center' }}>
				<Stack sx={{ position: 'absolute', bottom: '1rem', alignItems: 'center', width: '100vw' }}>
					<ArrowDownwardIcon sx={{ color: 'primary.contrastText', bgcolor: 'primary.dark', p: '1rem', borderRadius: '5rem' }} fontSize="large"/>
				</Stack>
				<Button variant='contained' color='secondary' size='large' sx={{ position: 'absolute', right: '2rem', top: '2rem' }} onClick={() => { navigate('/patients'); }}>Login</Button>			
				<Stack bgcolor="primary.main" sx={{ mx: isLargerThanPhone || (isLargerThanPhone && isTabletOrLarger) ? 'auto' : 0, p: isLargerThanPhone ? '3rem' : '0.5rem', borderRadius: isLargerThanPhone ? '3rem' : 0}}>
					<Stack direction="row" sx={{ alignItems: 'center', textAlign: 'center' }}>	
						<Box sx={{ maxHeight: isTabletOrLarger ? '4rem' : '3rem', maxWidth: isTabletOrLarger ? '4rem' : '3rem', p: isTabletOrLarger ? '1rem' : '0.5rem', borderRadius: '10rem', mx: '1rem', bgcolor: 'secondary.main' }}>
							<img src={require('../assets/drip_white.png')} alt='chartIV Icon' width='100%' height='100%'/>
						</Box>
						<Typography variant='h1' sx={{ color: 'white', fontSize: isTabletOrLarger ? '6rem' : '4rem' }}>chartIV</Typography>
					</Stack>
					<Typography variant='h3' sx={{ textAlign: 'center', fontSize: '1.5rem', my: '.7rem', color: 'white' }}>Charting for IV Therapy practices</Typography>
				</Stack>
			</Stack>
			<Stack sx={{ minHeight: '30rem', bgcolor: 'primary.main', alignItems: 'center', justifyContent: 'center', py: '2rem' }} spacing='2rem'>
				<Typography sx={{ color: 'primary.contrastText' }}variant='h2'>Services</Typography>
				<Stack direction="row" spacing="5rem" sx={{ justifyContent: 'center' }} useFlexGap flexWrap="wrap">
					<InfoBox>
						<Typography variant='h3' sx={{ textAlign: 'center' }}>Charting</Typography>	
						<List>
							<ListItem>
								<ListItemText primary="Unlimited user accounts"/>
							</ListItem>
							<ListItem>
								<ListItemText primary="Unlimited custom forms"/>
							</ListItem>
							<ListItem>
								<ListItemText primary="Patient-facing forms"/>
							</ListItem>
							<ListItem>
								<ListItemText primary="Unlimited form entries and patients"/>
							</ListItem>
						</List>
						<Box sx={{ bgcolor: 'primary.dark', borderRadius: '10rem' }}>
							<Typography variant='h3' sx={{ textAlign: 'center', fontSize: '2.5rem', color: 'primary.contrastText', p: '.5rem'}}>$0 / month</Typography>	
						</Box>
					</InfoBox>
					<InfoBox>
						<Typography variant="h3">More coming soon...</Typography>	
					</InfoBox>
				</Stack>
				<Link href="https://calendly.com/chartiv" variant="h3" color="secondary.contrastText" bgcolor="secondary.main" sx={{ px: '2rem', py: '1rem', borderRadius: '5rem' }}>Book a demo</Link>
			</Stack>
			<Stack spacing="1rem" sx={{ backgroundImage: `url(${require('../assets/cover.png')})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', bgcolor: 'secondary.main', minHeight: '20rem', py: '2rem', px: '20vw' }}>
				<ContactForm contactFormEntryVM={contactFormEntryVM}/>				
			</Stack>
		<Footer/>
		</Stack>
	);
}

export default LandingView;
