import {
	FormControl,
	TextField,
	Button,
	Link,
	CardHeader,
	CardContent,
	Stack
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import AuthViewModel, { AuthRequestBody, TooManyLoginAttemptsError } from '../viewmodels/AuthViewModel';
import { UnauthError, InvalidModelInputError } from '../viewmodels/ViewModel';
import CenteredContent from '../page_layouts/CenteredContent';

type Props = {
	authVM: AuthViewModel;
}

const Login: React.FC<Props> = ({ authVM }: Props) => {
	const navigate = useNavigate();
	const [username, setUsername] = useState<string>('');
	const [usernameError, setUsernameError] = useState<boolean>(false);
	const [usernameErrorText, setUsernameErrorText] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [passwordErrorText, setPasswordErrorText] = useState<string>('');

	async function authenticate(): Promise<void> {
		const authBody: AuthRequestBody = { username, password }
		try {
			await authVM.performAction('authenticate', { data: authBody });	
			navigate('/verify');
		}	
		catch(err) {
			if(err instanceof UnauthError) {
				setUsernameError(true);
				setPasswordError(true);
				setUsernameErrorText('Incorrect username or password')
				setPasswordErrorText('Incorrect username or password')
			}
			else if(err instanceof TooManyLoginAttemptsError) {
				alert('Account has been locked due to too many failed login attempts, and will be unlocked within an hour. If you believe this is a mistake, please contact admin@polfwack.tech.');
			}
			else if(err instanceof InvalidModelInputError) {
				if(err.errors.username) {
					setUsernameError(true);
					setUsernameErrorText('Username is required')
				}
				if(err.errors.password) {
					setPasswordError(true);
					setPasswordErrorText('Password is required')
				}
			}
			else {
				console.error(err);
				alert('An unexpected error occurred, please contact admin@polfwack.tech if this continues.');
			}
		}
	}

	return (
		<CenteredContent>
			<CardHeader sx={{ textAlign: 'center' }}title="chartIV Login"/>
			<CardContent sx={{ px: '2rem' }}>
				<Stack spacing="1rem">
				<FormControl fullWidth>
					<TextField 
						id='username' 
						label='Username' 
						value={username} 
						error={usernameError}
						helperText={usernameErrorText}
						onChange={event => { setUsername(event.target.value) }}
					/>
				</FormControl>
				<FormControl fullWidth>
					<TextField 
						id='password' 
						label='Password' 
						type='password' 
						error={passwordError}
						helperText={passwordErrorText}
						value={password} 
						onChange={event => { setPassword(event.target.value) }}
					/>
				</FormControl>
				<Stack direction="row" spacing="0.5rem" sx={{ alignItems: 'center' }}>
					<Button 
						variant='contained' 
						color='secondary' 
						onClick={() => { authenticate() }}
					>
						Login
					</Button>
					<Button variant='contained' color='error' onClick={() => { navigate('/') }}>Cancel</Button>
					<Link href='/request_password_reset' variant='body2'>Forgot password?</Link>
				</Stack>
				</Stack>
			</CardContent>
		</CenteredContent>
	);
}

export default Login;

