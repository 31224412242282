import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Component } from 'react';

import Patient from './models/Patient';
import FormEntry from './models/FormEntry';
import PFFormEntry from './models/PFFormEntry';
import ContactFormEntry from './models/ContactFormEntry';
import Form from './models/Form';
import PasswordResetToken from './models/PasswordResetToken';
import ViewModel from './viewmodels/ViewModel';
import AuthViewModel from './viewmodels/AuthViewModel';
import UserViewModel from './viewmodels/UserViewModel';
import PFFTokenViewModel from './viewmodels/PFFTokenViewModel';

// @ts-ignore
import theme from './theme.js';
import Login from './pages/Login';
import VerifyOTPView from './pages/VerifyOTPView';
import Patients from './pages/Patients';
import PatientView from './pages/PatientView';
import OrganizationView from './pages/OrganizationView';
import PatientInfoView from './pages/PatientInfoView';
import FormView from './pages/FormView';
import FormPage from './pages/FormPage';
import PFFView from './pages/PFFView';
import SendFormView from './pages/SendFormView'
import LandingView from './pages/LandingView';
import RequestPasswordResetView from './pages/RequestPasswordResetView';
import PasswordResetView from './pages/PasswordResetView';
import NewUserView from './pages/NewUserView';
import EditFormPage from './pages/EditFormPage';
import './style.css';

class App extends Component {

	render() {

		const userVM: UserViewModel = new UserViewModel();
		const patientVM: ViewModel<Patient> = new ViewModel<Patient>('patients');
		const formVM: ViewModel<Form> = new ViewModel('forms');
		const formEntryVM: ViewModel<FormEntry> = new ViewModel<FormEntry>('form_entries');
		const authVM: AuthViewModel = new AuthViewModel();
		const pffTokenVM: PFFTokenViewModel = new PFFTokenViewModel();
		const pfFormEntryVM: ViewModel<PFFormEntry> = new ViewModel<PFFormEntry>('pff_entry');
		const passwordResetTokenVM: ViewModel<PasswordResetToken> = new ViewModel<PasswordResetToken>('password_reset');
		const contactFormEntryVM: ViewModel<ContactFormEntry> = new ViewModel<ContactFormEntry>('email_form_entry');

		passwordResetTokenVM.removeAction('create');
		passwordResetTokenVM.addUnauthAction('create');
		contactFormEntryVM.removeAction('create');
		contactFormEntryVM.addUnauthAction('create');

		return (
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<Routes>
							<Route path="login" element={<Login authVM={authVM}/>}/>
							<Route path="verify" element={<VerifyOTPView authVM={authVM}/>}/>
							<Route path="users/new" element={<NewUserView userVM={userVM}/>}/>
							<Route path='organization' element={<OrganizationView userVM={userVM}/>}/>
							<Route path='patients'>
								<Route index element={<Patients title='chartIV' patientVM={patientVM}/>}/>
								<Route path='new' element={<PatientInfoView title='New Patient' patientVM={patientVM}/>}/>
								<Route path='edit/:id' element={<PatientInfoView title='Edit Patient' patientVM={patientVM}/>}/>
								<Route path='view/:id' element={<PatientView patientVM={patientVM} formEntryVM={formEntryVM} formVM={formVM}/>}/>
							</Route>
							<Route path='forms'>
								<Route index element={<FormPage formVM={formVM}/>}/>
								<Route path="new" element={<EditFormPage formVM={formVM} title="New Form"/>}/>
								<Route path="edit/:formId" element={<EditFormPage formVM={formVM} title="Edit Form"/>}/>
							</Route>
							<Route path="form_entry">
								<Route path='new/:patientId' element={<FormView title='New Form Entry' patientVM={patientVM} formVM={formVM} formEntryVM={formEntryVM}/>}/>
								<Route path='view/:id' element={<FormView title='New Form Entry' patientVM={patientVM} formVM={formVM} formEntryVM={formEntryVM} readOnly={true}/>}/>
								<Route path='edit/:id' element={<FormView title='Edit Form Entry' patientVM={patientVM} formVM={formVM} formEntryVM={formEntryVM}/>}/>
								<Route path='send/:patientId' element={<SendFormView pffTokenVM={pffTokenVM} patientVM={patientVM} formVM={formVM}/>}/>
							</Route>
							<Route path='pff'>
								<Route path=':token' element={<PFFView pffTokenVM={pffTokenVM} pfFormEntryVM={pfFormEntryVM}/>}/>
								<Route path='success' element={<p>Thank you! Your entry has been recorded.</p>}/>
							</Route>
							<Route path='request_password_reset' element={<RequestPasswordResetView passwordResetTokenVM={passwordResetTokenVM}/>}/>
							<Route path='password_reset/:token' element={<PasswordResetView userVM={userVM}/>}/>
							<Route index element={<LandingView contactFormEntryVM={contactFormEntryVM}/>}/>
						</Routes>
					</BrowserRouter>
				</ThemeProvider>
			);
	}
  
}

export default App;
