import {
	Box,
	Container,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import NavBar from '../components/NavBar';
import FormEntryView from '../components/FormEntryView';
import Patient from '../models/Patient';
import FormEntry from '../models/FormEntry'
import Form from '../models/Form';
import ViewModel, { UnauthError } from '../viewmodels/ViewModel';

type Props = {
	patientVM: ViewModel<Patient>;
	formVM: ViewModel<Form>;
	formEntryVM: ViewModel<FormEntry>;
	title: string;
	readOnly?: boolean;
}

const FormView: React.FC<Props> = ({ patientVM, formVM, formEntryVM, title, readOnly }) => {
	const { id, patientId } =  useParams();
	const [formEntry, setFormEntry] = useState<FormEntry>();
	const [patients, setPatients] = useState<Patient[]>([]);
	const [forms, setForms] = useState<Form[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchData() {
			try {
				const patientData = await patientVM.performAction('get');
				const formData = await formVM.performAction('get');

				if(id) {
					const formEntryData = await formEntryVM.performAction('retrieve', { id: Number(id) });
					setFormEntry(formEntryData);
				}

				else if(patientId) {
					setFormEntry({ patient: Number(patientId), form: '', form_field_entries: [], date: dayjs().format('YYYY-MM-DD'), notes: '' })
				}

				setForms(formData);
				setPatients(patientData);
			}
			catch(err) {
				if(err instanceof UnauthError) {
					navigate('/login');
				}

				throw err;
			}
		}

		fetchData();
		
	}, [id, patientId, patientVM, formVM, formEntryVM, navigate]);

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>
			<Container>
				<Typography variant='h1' sx={{ textAlign: 'center' }}>{ title }</Typography>
				<FormEntryView formEntry={formEntry} readOnly={readOnly} patients={patients} forms={forms} formEntryVM={formEntryVM}/>
			</Container>
		</Box>
	);
}

export default FormView;
