import React, { useState, useEffect } from 'react';
import {
	Stack,
	Typography,
	FormControl,
	TextField,
	Tooltip,
	IconButton,
	Button,
	FormControlLabel,
	Checkbox
	
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import ViewModel, { UnauthError, InvalidModelInputError, ModelNotFoundError, UnexpectedError } from '../viewmodels/ViewModel';
import Form from '../models/Form';
import NavBarContent from '../page_layouts/NavBarContent';

type Props = {
	formVM: ViewModel<Form>;	
	title?: string;
}

const EditFormPage: React.FC<Props> = ({ formVM, title }) => {
	const { formId } = useParams();
	const [isPatientFacing, setIsPatientFacing] = useState(false);
	const [name, setName] = useState<string>('');
	const [fields, setFields] = useState<string[]>([]);
	const [errors, setErrors] = useState<any>({});
	const navigate = useNavigate();

	useEffect(() => {
		function getForm(formId: string | undefined) {
			var form = formVM.getModels().find(ele => ele.id === Number(formId));
			if(form) {
				setIsPatientFacing(form.is_patient_facing);
				setName(form.name);
				setFields(form.fields.map(field => field.label));
			}
			return form;
		}

		async function fetchData() {
			await formVM.performAction('get');
			getForm(formId)
		}

		if(formId) {
			if(!getForm(formId)) {
				fetchData();
			}
		}
		
	}, [formId, formVM]);

	function handleFieldLabelChange(value: string, i: number) {
		setFields([...fields.slice(0, i), value, ...fields.slice(i + 1)]);
	}

	async function save() {
		try {
			await formVM.performAction(formId ? 'update' : 'create', { id: Number(formId), data: { name, fields: fields.map((label) => { return { label } }), is_patient_facing: isPatientFacing } });
			navigate('/forms')
		}
		catch(err) {
			if(err instanceof UnauthError) {
				navigate('/login');
			}
			else if(err instanceof InvalidModelInputError) {
				setErrors(err.errors);
			}
			else if(err instanceof ModelNotFoundError) {
				alert("You're trying to edit a form that doesn't exist, please contact admin@polfwack.tech.");
				navigate('/forms');
			}
			else if(err instanceof UnexpectedError) {
				alert('An unexpected error occurred, please contact admin@polfwack.tech.');
			}
			else {
				throw err;
			}
		}
	}

	return (
		<NavBarContent>	
			<Stack spacing="1rem">
				<Stack spacing="0.5rem">
					<Typography variant="h1" sx={{ textAlign: 'center' }}>{ title ?? 'Edit Form' }</Typography>
					<FormControlLabel control={<Checkbox checked={isPatientFacing} onChange={event => setIsPatientFacing(event.target.checked)}/>} label="Patient Facing"/> 
					<FormControl fullWidth>
						<TextField label="Name" value={name} onChange={ event => setName(event.target.value) } helperText={errors.name ? errors.name : ''} error={errors.name ? true : false} />
					</FormControl>
					{fields.map((label, i) => (
					<Stack key={`field-${i}`} direction="row" spacing="0.5rem" sx={{ alignItems: 'center' }}>
						<FormControl fullWidth>
							<TextField 
								label="Field Label" 
								value={label}
								error={errors.fields ? Object.keys(errors.fields[i]).length > 0 : false}
								helperText={errors.fields && Object.keys(errors.fields[i]).length > 0 ? errors.fields[i].label : ''}
								onChange={event => { handleFieldLabelChange(event.target.value, i); }}
							/>
						</FormControl>
						<Tooltip title="Delete field">
							<IconButton
								size="small"
								data-testid={`delete-field-${i}-button`}
								sx={{ bgcolor: 'error.main', color: 'error.contrastText', '&:hover': { bgcolor: 'error.dark' } }}
								onClick={() => { setFields([...fields.filter((ele, index) => index !== i)]) }}
							>
								<DeleteIcon/>
							</IconButton>
						</Tooltip>
					</Stack>
					))}
					<Stack sx={{ alignItems: 'center' }}>
						<Tooltip title="Create new form">
							<IconButton
								size="small"
								data-testid="add-field-button"
								sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { bgcolor: 'secondary.dark' } }} 
								onClick={() => { setFields([...fields, '']) }}
							>
								<AddIcon/>
							</IconButton>
						</Tooltip>
					</Stack>
					<Stack direction="row" spacing="0.5rem">
						<Button variant="contained" color="secondary" onClick={save}>Save</Button>	
						<Button variant="contained" color="error" onClick={() => { navigate('/forms') }}>Cancel</Button>	
					</Stack>
				</Stack>
			</Stack>
		</NavBarContent>	
	);

}

export default EditFormPage;
