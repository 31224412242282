import ViewModel, { ActionConfig, InvalidModelInputError } from './ViewModel';
import { RequestConfig } from '../utils/requests';
import axios from 'axios';

type Auth = {
	access: string;
	refresh: string;
}

type AuthResponseBody = {
	email_device_id: number;
}

type AuthRequestBody = {
	username: string;
	password: string;
}

type AuthActionConfig = ActionConfig & {
	data: AuthRequestBody
}

class AuthViewModel extends ViewModel<Auth> {
	protected actions: string[] = [];
	protected unauthActions: string[] = ['authenticate', 'verify'];
	protected username: string;
	protected emailDeviceId: number;

	constructor(username: string = '', emailDeviceId: number = -1, client: Function = axios) {
		super('token', [], client);
		this.username = username;
		this.emailDeviceId = emailDeviceId;
	}

	getUsername(): string {
		return this.username;
	}

	getEmailDeviceId(): number {
		return this.emailDeviceId;
	}

	async authenticate({ data }: AuthActionConfig, requestFunction: Function): Promise<void> {
		const requestConfig: RequestConfig = {
			method: 'post',
			path: this.path,
			data
		}
		
		try{
			const { email_device_id } = await requestFunction(requestConfig, this.client);
			this.username = data.username;
			this.emailDeviceId = email_device_id;
		}
		catch(err) {
			if(axios.isAxiosError(err)) {
				if(err.response?.status === 403) {
					throw new TooManyLoginAttemptsError(err.response?.data?.detail);
				}
				if(err.response?.status === 422) {
					throw new InvalidModelInputError('Invalid auth input', err.response?.data?.errors);
				}
			}
			throw err;	
		}
	}

	async verify({ data }: AuthActionConfig, requestFunction: Function): Promise<void> {
		const requestConfig: RequestConfig = {
			method: 'post',
			path: `${this.path}/verify_otp`,
			data: {
				otp: data.otp,
				username: this.username,
				email_device_id: this.emailDeviceId
			}
		}

		try {
			
			const { access, refresh } = await requestFunction(requestConfig, this.client);
			localStorage.setItem('access', access);
			localStorage.setItem('refresh', refresh);
		}
		catch(err) {
			if(axios.isAxiosError(err)) {
				if(err.response?.status === 403) {
					throw new TooManyLoginAttemptsError(err.response?.data?.detail);
				}
				if(err.response?.status === 422) {
					throw new InvalidModelInputError('Invalid auth input', err.response?.data?.errors);
				}
			}

			throw err;
		}
	}
}

class TooManyLoginAttemptsError extends Error {}

export default AuthViewModel;

export {
	AuthRequestBody,
	AuthResponseBody,
	TooManyLoginAttemptsError
}
