import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Card,
	CardHeader,
	CardContent,
	Stack,
	FormControl, 
	TextField, 
	Button 
} from '@mui/material';

import AuthViewModel, { TooManyLoginAttemptsError } from '../viewmodels/AuthViewModel';
import { UnauthError, InvalidModelInputError } from '../viewmodels/ViewModel';

import CenteredContent from '../page_layouts/CenteredContent';

type Props = {
	authVM: AuthViewModel;
}

const VerifyOTPView: React.FC<Props> =  ({ authVM }) => {
	const navigate = useNavigate();
	const [otp, setOTP] = useState('');
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('');

	async function verify() {
		try {
			await authVM.performAction('verify', { data: { otp } });
			navigate('/patients');
		}
		catch(err) {
			if(err instanceof TooManyLoginAttemptsError) {
				alert('Account has been locked due to too many failed login attempts, and will be unlocked within an hour. If you believe this is a mistake, please contact admin@polfwack.tech.');
			}
			else if(err instanceof UnauthError) {
				setErrorText('Invalid one-time password');
				setError(true);
			}
			else if(err instanceof InvalidModelInputError) {
				if(err.errors.otp) {
					setErrorText('One-time password is required');
					setError(true);
				}
			}
		}
	}

	return (
		<CenteredContent>	
			<Card>
				<CardHeader title="Verify with One-Time Password" titleTypographyProps={{ textAlign: 'center' }}/>
				<CardContent sx={{ px: '2rem' }}>
					<Stack spacing="1rem" sx={{ textAlign: 'center'}}>
						<FormControl fullWidth>
							<TextField label="One-Time Password" value={otp} helperText={errorText} error={error} onChange={event => { setOTP(event.target.value); }}/>
						</FormControl>
						<Stack direction="row" spacing="0.5rem">
							<Button variant="contained" color="secondary" onClick={verify} >Verify</Button>
							<Button variant="contained" color="error" onClick={ () => { navigate('/') } }>Cancel</Button>
						</Stack>
					</Stack>
				</CardContent>
			</Card>
		</CenteredContent>	
	);
}

export default VerifyOTPView;
