import React, { useEffect, useState } from 'react';
import {
	Stack,
	Typography,
	IconButton,
	Tooltip,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ViewModel, { UnauthError, UnexpectedError } from '../viewmodels/ViewModel';
import Form from '../models/Form';
import NavBarContent from '../page_layouts/NavBarContent';

type Props = {
	formVM: ViewModel<Form>
}

const FormPage: React.FC<Props> = ({ formVM }) => {
	const [forms, setForms] = useState<Form[]>([]);
	const navigate = useNavigate();

	async function deleteForm(id: number) {
		await formVM.performAction('delete', { id });
		setForms(formVM.getModels().filter((form: Form) => form.active === true))
	}

	useEffect(() => {
		async function fetchData() {
			try {
				await formVM.performAction('get');
				setForms(formVM.getModels().filter((form: Form) => form.active === true));
			}
			catch(err) {
				if(err instanceof UnauthError)	{
					navigate('/login')
				}
				else if(err instanceof UnexpectedError) {
					alert('An unexpected error occurred, please contact admin@polfwack.tech.');
				}
				else {
					throw err;
				}
			}
		}

		fetchData();
	}, [formVM, navigate]);
		
	return (
		<NavBarContent>
			<Stack spacing="1rem">
				<Typography variant="h1" sx={{ textAlign: 'center' }}>Forms</Typography>
				<Stack spacing="0.5rem">
					<Stack direction="row">
						<Tooltip title="Create new form">
							<IconButton
								data-testid="create-button"
								sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { bgcolor: 'secondary.dark' } }} 
								onClick={() => { navigate('/forms/new') }}
							>
								<AddIcon/>
							</IconButton>
						</Tooltip>
					</Stack>
					<Paper>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow><TableCell>Name</TableCell><TableCell>Actions</TableCell></TableRow>
								</TableHead>
								<TableBody>
									{forms.map((form: Form) => (
									<TableRow key={form.id}>
										<TableCell>{form.name}</TableCell>
										<TableCell>
											<Stack direction="row" spacing="0.2rem">
												<Tooltip title="Edit form">	
													<IconButton 
														data-testid={`edit-${form.id}-button`}
														size="small"
														onClick={() => { navigate(`/forms/edit/${form.id}`) }}
													>
														<EditIcon fontSize="small"/>		
													</IconButton>
												</Tooltip>	
												<Tooltip title="Delete form">	
													<IconButton 
														data-testid={`delete-${form.id}-button`}
														size="small"
														sx={{ bgcolor: 'error.main', color: 'error.contrastText', '&:hover': { bgcolor: 'error.dark' } }}
														onClick={() => { deleteForm((form.id as number)); }}
													>
														<DeleteIcon fontSize="small"/>		
													</IconButton>
												</Tooltip>
											</Stack>
										</TableCell>
									</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Stack>
			</Stack>
		</NavBarContent>
	);
}

export default FormPage;

