import React, { useState } from 'react';
import {
	Button,
	Typography,
	Container,
	FormControl,
	TextField
} from '@mui/material';

import ViewModel, { InvalidModelInputError } from '../viewmodels/ViewModel';
import ContactFormEntry from '../models/ContactFormEntry';

type Props = {
	contactFormEntryVM: ViewModel<ContactFormEntry>;
}

const ContactForm: React.FC<Props> = ({ contactFormEntryVM }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [confirmEmail, setConfirmEmail] = useState('');
	const [message, setMessage] = useState('');

	const [nameError, setNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [messageError, setMessageError] = useState(false);


	const [nameErrorMessage, setNameErrorMessage] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [messageErrorMessage, setMessageErrorMessage] = useState('');

	async function sendEmailForm() {
		if(email !== confirmEmail) {
			setEmailError(true);
			setEmailErrorMessage('Emails do not match');
			return;
		}

		try {
			await contactFormEntryVM.performAction('create', { data: { name, email, message } });
			setNameError(false);
			setEmailError(false);
			setMessageError(false);
			setNameErrorMessage('');
			setEmailErrorMessage('');
			setMessageErrorMessage('');
			setName('');
			setEmail('');
			setConfirmEmail('');
			setMessage('');
			alert('Thank you! Your message has been sent.');
		}
		catch(err) {
			if(err instanceof InvalidModelInputError) {
				if(err.errors['name']) {
					setNameError(true);
					setNameErrorMessage(err.errors['name'] as string);
				}
				if(err.errors['email']) {
					setEmailError(true);
					setEmailErrorMessage(err.errors['email'] as string);
				}
				if(err.errors['message']) {
					setMessageError(true);
					setMessageErrorMessage(err.errors['message'] as string);
				}
			}

			else {
				console.error(err);
				alert('An unexpected error has occurred, please contact admin@polfwack.tech');
			}
		}
	}

	return (
		<Container sx={{ bgcolor: 'white', p: '2rem', borderRadius: '2rem' }}>
			<Typography variant='h3' sx={{ mb: '.5rem' }}>Contact Us</Typography>
			<FormControl fullWidth sx={{ mb: '1rem' }}>
				<TextField
					error={nameError}
					helperText={nameErrorMessage}
					variant='filled'
					label='Name'
					value={name}
					onChange={event => { setName(event.target.value); }}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: '1rem' }}>
				<TextField
					error={emailError}
					helperText={emailErrorMessage}
					variant='filled'
					label='Email'
					value={email}
					onChange={event => { setEmail(event.target.value); }}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: '1rem' }}>
				<TextField
					error={emailError}
					helperText={emailErrorMessage}
					variant='filled'
					label='Confirm Email'
					value={confirmEmail}
					onChange={event => { setConfirmEmail(event.target.value); }}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: '1rem' }}>
				<TextField
					error={messageError}
					helperText={messageErrorMessage}
					variant='filled'
					label='Message'
					multiline
					rows={5}
					value={message}
					onChange={event => { setMessage(event.target.value); }}
				/>	
			</FormControl>
			<Button variant='contained' color='secondary' size='large' onClick={() => { sendEmailForm(); }}>Send</Button>
		</Container>
	);
}

export default ContactForm;
