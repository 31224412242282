import React, { ReactNode } from 'react';
import {
	Box,
	Container
} from '@mui/material';

import NavBar from '../components/NavBar';

type Props = {
	children: ReactNode;
}

const NavBarContent: React.FC<Props> = ({ children }) => {
	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>	
			<Box component="main" sx={{ flexGrow: 1 }}>
				<Container>{ children }</Container>
			</Box>
		</Box>
	);
}

export default NavBarContent;
