import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Container,
	Typography,
	Stack,
	FormControl,
	TextField,
	Button
} from '@mui/material';

import NavBar from '../components/NavBar';
import UserViewModel from '../viewmodels/UserViewModel';
import { UnauthError, InvalidModelInputErrors, InvalidModelInputError } from '../viewmodels/ViewModel';

type Props = {
	userVM: UserViewModel;
}


const NewUserView: React.FC<Props> = ({ userVM }) => {
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [errors, setErrors] = useState<InvalidModelInputErrors>({});

	const save = async () => {
		const data = {
			first_name: firstName,
			last_name: lastName,
			username,
			email
		}

		try {
			await userVM.performAction('create', { data });
			navigate('/organization')
		}
		catch(err) {
			if(err instanceof UnauthError) {
				navigate('/login');
			}
			if(err instanceof InvalidModelInputError) {
				setErrors(err.errors);
			}
		}
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>
			<Box component='main' sx={{ flexGrow: 1 }}>
				<Container>
					<Stack spacing="1rem">
						<Typography variant="h1" sx={{ textAlign: 'center' }}>New User</Typography>
						<FormControl fullWidth>
							<TextField 
								value={username} 
								variant="standard"
								label="Username" 
								onChange={event => { setUsername(event.target.value) }}
								error={errors.username ? true : false}
								helperText={errors.username ? errors.username[0] : ''}
							/>
						</FormControl>
						<FormControl fullWidth>
							<TextField 
								value={email} 
								variant="standard"
								label="Email" 
								onChange={event => { setEmail(event.target.value) }}
								error={errors.email ? true : false}
								helperText={errors.email ? errors.email[0] : ''}
							/>
						</FormControl>
						<FormControl fullWidth>
							<TextField 
								value={firstName} 
								variant="standard"
								label="First Name" 
								onChange={event => { setFirstName(event.target.value) }}
								error={errors.first_name ? true : false}
								helperText={errors.first_name ? errors.first_name[0] : ''}
							/>
						</FormControl>
						<FormControl fullWidth>
							<TextField 
								value={lastName} 
								variant="standard"
								label="Last Name" 
								onChange={event => { setLastName(event.target.value) }}
								error={errors.last_name ? true : false}
								helperText={errors.last_name ? errors.last_name[0] : ''}
							/>
						</FormControl>
						<Stack direction="row" spacing="1rem">
							<Button variant="contained" color="secondary" onClick={save}>Save</Button>
							<Button variant="contained" color="error" onClick={() => { navigate('/organization') }}>Cancel</Button>
						</Stack>
					</Stack>
				</Container>
			</Box>
		</Box>
	);
}

export default NewUserView;
