import axios from 'axios';
import { authRequest, unauthRequest } from '../utils/requests';

type ActionConfig = {
	id?: number,
	data?: any
}

class ViewModel<T> {
	[key: string]: any;

	protected path: string;
	protected models: T[];
	protected actions: string[] = ['get', 'retrieve', 'create', 'update', 'delete'];
	protected unauthActions: string[] = [];
	protected client: Function;


	constructor(path: string, models: T[] = [], client: Function = axios) {
		this.path = path;
		this.models = models;
		this.client = client;
	}

	getActions(): string[] {
		return this.actions;
	}

	getUnauthActions(): string[] {
		return this.unauthActions;
	}

	addAction(action: string): void {
		if(!this.actions.find(ele => ele === action)) {
			this.actions.push(action);
		}
	}

	addUnauthAction(action: string): void {
		if(!this.unauthActions.find(ele => ele === action)) {
			this.unauthActions.push(action);
		}
	}

	removeAction(action: string): void {
		this.actions = this.actions.filter((ele: string) => ele !== action);
	}

	removeUnauthAction(action: string): void {
		this.unauthActions = this.unauthActions.filter((ele: string) => ele !== action);
	}

	getModels(): T[] {
		return this.models;
	}

	getPath(): string {
		return this.path;
	}

	async get(config: ActionConfig, requestFunction: Function): Promise<T[]> {
		const requestConfig = {
			method: 'get',
			path: this.path, 
		};

		try {
			this.models = (await requestFunction(requestConfig, this.client)).results;
			return this.models;
		}
		catch(err) {
			if(axios.isAxiosError(err)) { 
				switch(err.response?.status) {
					default:
						throw err;
				}
			}

			throw err;
		}

	}

	async retrieve({ id }: ActionConfig, requestFunction: Function): Promise<T> {
		const requestConfig = {
			method: 'get',
			path: `${this.path}/${id}`, 
		};

		try {
			return await requestFunction(requestConfig, this.client);
		}
		catch(err) {
			if(axios.isAxiosError(err)) { 
				switch(err.response?.status) {
					case 404:
						throw new ModelNotFoundError(`Model ${id} not found`, id);
					default:
						throw err;
				}
			}

			throw err;
		}
	}

	async create({ data }: ActionConfig, requestFunction: Function): Promise<T> {
		const requestConfig = {
			method: 'post',
			path: this.path, 
			data,
		};

		try {
			const model = await requestFunction(requestConfig, this.client);
			this.models.push(model);
			return model;
		}
		catch(err) {
			if(axios.isAxiosError(err)) { 
				switch(err.response?.status) {
					case 422:
						throw new InvalidModelInputError('Invalid model input', err.response?.data?.errors);
					default:
						throw err;
				}
			}

			throw err;
		}
	}

	async update({ id, data }: ActionConfig, requestFunction: Function): Promise<T> {
		const requestConfig = {
			method: 'patch',
			path: `${this.path}/${id}`,
			data,
		}

		type Model = T & {
			id: number;
		}

		try {
			const model: Model = await requestFunction(requestConfig, this.client);
			const modelIndex = this.models.findIndex(ele => model.id === (ele as Model).id);
			if(modelIndex !== -1) {
				this.models[modelIndex] = model;
			}
			else {
				this.models.push(model);
			}

			return model;
		}
		catch(err) {
			if(axios.isAxiosError(err)) { 
				switch(err.response?.status) {
					case 404:
						throw new ModelNotFoundError(`Model ${id} not found`, id);
					case 422:
						throw new InvalidModelInputError('Invalid model input', err.response?.data?.errors);
					default:
						throw err;
				}
			}

			throw err;
		}

	}

	async delete({ id }: ActionConfig, requestFunction: Function): Promise<void> {
		const requestConfig = {
			method: 'delete',
			path: `${this.path}/${id}`
		}

		type Model = T & {
			id: number;
		}

		try {
			await requestFunction(requestConfig, this.client);
			this.models = this.models.filter((model: T) => (model as Model).id !== id)
		}
		catch(err) {
			if(axios.isAxiosError(err)) {
				switch(err.response?.status) {
					case 404:
						throw new ModelNotFoundError(`Model ${id} not found`, id);
					default:
						throw err;
				}
			}

			throw err;
		}
	}

	async performAction(action: string, config: ActionConfig = {}) {
		try {
			if(this.actions.find(ele => ele === action) && this[action] instanceof Function) {
				return await (this[action] as Function)(config, authRequest);
			}
			else if(this.unauthActions.find(ele => ele === action) && this[action] instanceof Function) {
				return await (this[action] as Function)(config, unauthRequest);
			}
			throw new ActionNotSupportedError(`Action '${action}' is not supported`);
		}
		catch(err) {
			if(axios.isAxiosError(err)) {
				switch(err.response?.status ?? -1) {
					case 400:
					case 401:
						throw new UnauthError('Unauthenticated');
					default:
						throw new UnexpectedError('An unexpected error occurred', err.response?.status);
				}
			}

			throw err;
		}
	}
}

class UnauthError extends Error {
}

class ModelNotFoundError extends Error {
	private id: number;

	constructor(message: string, id: number = -1) {
		super(message);
		this.id = id;
	}
}

type InvalidModelInputErrors = {
	[key: string]: string[] | string;
}

class InvalidModelInputError extends Error {
	public errors: InvalidModelInputErrors;

	constructor(message: string, errors: InvalidModelInputErrors) {
		super(message);
		this.errors = errors;
	}
}

class UnexpectedError extends Error {
	public statusCode: number;

	constructor(message: string, statusCode: number = -1) {
		super(message);
		this.statusCode = statusCode;
	}
}

class ActionNotSupportedError extends Error {

}

export default ViewModel;

export {
	ActionConfig,
	UnauthError,
	ModelNotFoundError,
	InvalidModelInputError,
	InvalidModelInputErrors,
	UnexpectedError,
	ActionNotSupportedError
}
