import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: ['Montserrat'],
		h1: {
			fontFamily: ['Lexend']
		},
		h2: {
			fontFamily: ['Lexend']
		},
		h3: {
			fontFamily: ['Lexend'],
			fontSize: '2rem'
		}
	},
	palette: {
		primary: {
			main: '#7EBDC2',
			dark: '#459AAB',
			contrastText: '#FFF'
		},
		secondary: {
			main: '#97CB8D',
			contrastText: '#FFF'
		}
	}
});

export default theme;
