import {
	Container,
	Typography,
	FormControl,
	TextField,
	Button
} from '@mui/material';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { InvalidModelInputError, ModelNotFoundError } from '../viewmodels/ViewModel';
import UserViewModel from '../viewmodels/UserViewModel';

type Props = {
	userVM: UserViewModel;
}

const PasswordResetView: React.FC<Props> = ({ userVM }) => {
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('Passwords do not match')
	const { token } = useParams();
	const navigate = useNavigate();

	async function submit() {
		if(newPassword !== confirmPassword) {
			setError(true);
			return;
		}

		try {
			await userVM.performAction('password_reset', { data: { password: newPassword, token } });
			alert('Password change successful.');
			navigate('/login');
		}
		catch(err) {
			if(err instanceof InvalidModelInputError) {
				setError(true);
				setErrorText((err as InvalidModelInputError).errors.password as string);
			}
			else if(err instanceof ModelNotFoundError) {
				setError(true);
				setErrorText((err as ModelNotFoundError).message);
			}
			else {
				alert('An unexpected error occurred, please contact admin@polfwack.tech.');
			}
		}
	}

	return (
		<Container>
			<Typography variant='h1'>Password Reset</Typography>
			<FormControl fullWidth sx={{
				mb: '1rem',
					'& .MuiTextField-root': {
						mt: '1rem'
					}
			}}>
				<TextField 
					id='newPassword' 
					label='New Password' 
					type='password'
					value={newPassword} 
					onChange={event => { setNewPassword(event.target.value); }} 
					helperText={error ? errorText : ''}
					error={error}
				/>
				<TextField 
					id='confirmPassword' 
					label='Confirm Password' 
					type='password'
					value={confirmPassword} 
					onChange={event => { setConfirmPassword(event.target.value); }} 
					helperText={error ? errorText : ''}
					error={error}
				/>
			</FormControl>
			<Button variant='contained' color='secondary' onClick={submit}>Submit</Button>
		</Container>
	);
}

export default PasswordResetView;
