import {
	Box,
	Container,
	Typography,
	FormControl,
	Select,
	TextField,
	Button,
	MenuItem,
	InputLabel
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import ViewModel, { InvalidModelInputError, UnauthError } from '../viewmodels/ViewModel';
import PFFTokenViewModel from '../viewmodels/PFFTokenViewModel';
import Patient from '../models/Patient';
import Form from '../models/Form';
import NavBar from '../components/NavBar';

type Props = {
	pffTokenVM: PFFTokenViewModel;
	patientVM: ViewModel<Patient>;
	formVM: ViewModel<Form>;
}

const SendFormView: React.FC<Props> = ({ pffTokenVM, patientVM, formVM }) => {
	const { patientId } = useParams();
	const [patients, setPatients] = useState([]);
	const [patient, setPatient] = useState({ first_name: '', last_name: '' });
	const [forms, setForms] = useState([]);
	const [form, setForm] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchData() {
			try {
				setPatients((await patientVM.performAction('get')));
				setForms((await formVM.performAction('get')).filter((ele: Form) => ele.is_patient_facing && ele.active));
			}
			catch(err) {
				if(err instanceof UnauthError) {
					navigate('/login');	
				}
			}
		}

		fetchData();
	}, [patientId, navigate, patientVM, formVM]);

	useEffect(() => {
		const newPatientValue = patients.find((ele: Patient) => Number(ele.id) === Number(patientId));
		if(newPatientValue) {
			setPatient(newPatientValue);
		}
	}, [patientId, patients]);

	async function sendForm() {
		try {

			await pffTokenVM.performAction('create', { data: { patient: Number(patientId), form: Number(form) } });
			alert('Successfully sent form request');
			navigate(`/patients/view/${patientId}`);
		}	
		catch(err) {
			if (err instanceof InvalidModelInputError) {
				alert((err as InvalidModelInputError).errors.inputs);
			}
		}
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar/>		
			<Container>
				<Typography variant='h1' sx={{ textAlign: 'center' }}>Send Form</Typography>
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<TextField label='Patient' value={`${patient.first_name} ${patient.last_name}`} disabled={true}/>
				</FormControl>
				<FormControl fullWidth sx={{ mt: '1rem' }}>
					<InputLabel id='form-select-label'>Form</InputLabel>
					<Select labelId='form-select-label' value={form} label='Form' onChange={event => { setForm(event.target.value); }}>
						{forms.map((ele: Form) => (
						<MenuItem key={`form-${ele.id}`} value={ele.id}>{ele.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<Box sx={{ mt: '1rem' }}>
					<Button variant='contained' color='secondary' sx={{ mr: '.5rem' }}onClick={sendForm}>Send</Button>
					<Button variant='contained' color='error' onClick={() => { navigate(`/patients/view/${patientId}`) }}>Cancel</Button>
				</Box>
			</Container>
		</Box>
	);

}

export default SendFormView;
