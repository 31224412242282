import ViewModel, { ActionConfig, ModelNotFoundError } from './ViewModel';
import PFFToken from '../models/PFFToken';
import axios from 'axios';

type RetrieveActionConfig = ActionConfig & {
	token?: string;
}

class PFFTokenViewModel extends ViewModel<PFFToken> {
	protected actions: string[] = ['create'];
	protected unauthActions: string[] = ['retrieve'];

	constructor(client: Function = axios) {
		super('pff_token', [], client);
	}

	async retrieve({ token }: RetrieveActionConfig, requestFunction: Function): Promise<PFFToken> {
		const requestConfig = {
			method: 'get',
			path: `${this.path}/${token}`, 
		};

		try {
			return await requestFunction(requestConfig, this.client);
		}
		catch(err) {
			if(axios.isAxiosError(err)) { 
				switch(err.response?.status) {
					case 404:
						throw new ModelNotFoundError(`Token ${token} not found`, -1);
					default:
						throw err;
				}
			}

			throw err;
		}
	}

	async performAction(action: string, config: RetrieveActionConfig = {}) {
		return await super.performAction(action, config);
	}
}

export default PFFTokenViewModel;
