import {
	Stack,
	Typography,
	Link,
	IconButton
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useMediaQuery } from 'react-responsive';

function Footer() {
	const isTabletOrLarger = useMediaQuery({ query: '(min-width: 768px)' });

	return (
		<Stack sx={{ minHeight: '7rem', bgcolor: 'primary.main', alignItems: 'center', justifyContent: 'center' }}>
			<Stack direction='row' sx={{ alignItems: 'center'}} spacing="0.5rem">
				<img width='60rem' height='60rem' src={require('../assets/polfwack_logo.png')} alt='PolfWack Technologies, LLC logo'/>
				<Typography sx={{ fontSize: isTabletOrLarger ? '1.2rem': '1rem', color: 'primary.contrastText' }}>Powered by PolfWack Technologies, LLC</Typography>
				<IconButton size="small" href="https://www.instagram.com/chart.iv/">
					<InstagramIcon fontSize="large" sx={{ color: 'primary.contrastText' }}/>
				</IconButton>
				<IconButton size="small" href="https://www.facebook.com/people/Chart-IV/61560263046212/">
					<FacebookIcon fontSize="large" sx={{ color: 'primary.contrastText' }}/>
				</IconButton>
			</Stack>
			<Stack direction="row" spacing="1rem">
				<Link sx={{ fontSize: '0.7rem', color: 'primary.contrastText' }} href="/terms-of-service.html" variant="body1">Terms of Service</Link>
				<Typography sx={{ fontSize: '0.7rem', color: 'primary.contrastText' }}>chartIV logo was provided by FlatIcon</Typography>
			</Stack>
		</Stack>
	);
}

export default Footer;
