import React, { ReactNode } from 'react';
import {
	Box,
	Container,
	Stack,
	Card
} from '@mui/material';

type Props = {
	children: ReactNode;
}

const CenteredContent: React.FC<Props> = ({ children }) => {
	return (
		<Box sx={{ bgcolor: 'primary.main' }}>
			<Container>
				<Stack sx={{  justifyContent: 'center', height: '100vh' }}>
					<Card>
						{ children }
					</Card>
				</Stack>
			</Container>
		</Box>
	);
}

export default CenteredContent;
